import React from 'react';
import { Content } from 'patterns/Content';
import { Masthead } from 'patterns/Masthead';
import { Shell } from 'patterns/Shell';

import '../scss/main.scss';

function NotFoundPage({
    location: {
        pathname,
    },
}) {
    const data = {
        masthead: {
            title: '404',
            description: [
                {
                    spans: [],
                    text: 'You just hit a page that doesn’t exist... the sadness.',
                    type: 'paragraph',
                },
            ],
            image: {
                alt: '',
                dimensions: {
                    height: 4000,
                    width: 6000,
                },
                url: 'https://images.prismic.io/robsimpson/ac246b06-5001-4d48-bef2-03838a3b1e99_privacy.jpg?auto=compress,format'
            },
        },
    };

    return (
        <Shell
            pageDescription=""
            pageSocialImage=""
            pageTitle="404 Page Not Found"
            pathname={pathname}
        >
            <Masthead
                description={data.masthead.description}
                image={data.masthead.image}
                title={data.masthead.title}
            />
        </Shell>
    );
}

export default NotFoundPage;
